/* eslint no-console: 0, no-unused-vars: 0, no-undef: 0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import Sentry from '~/util/sentry'
import vuetify from '~/util/vuetify'
import axios from '~/util/axios'
import apolloClient from '~/util/apolloClient'
import Router from 'vue-router'
import '~/assets/css/prefix.scss'
import snackbard from 'snackbard'
import { VueMaskDirective } from 'v-mask'
import LoginApp from '~/components/login/LoginApp.vue'

import {
  snBtn,
  snGradientHeader,
  snLabel,
  snPassword,
  snRadio,
  snRadioGroup
} from '@simplenexus/snui'
import '@simplenexus/snui/dist/assets/index.css' // Styles from SNUI repo

import Login from '~/components/login/Login.vue'
import PasswordReset from '~/components/passwordReset/passwordReset.vue'

Vue.component('snBtn', snBtn)
Vue.component('snGradientHeader', snGradientHeader)
Vue.component('snLabel', snLabel)
Vue.component('snPassword', snPassword)
Vue.component('snRadio', snRadio)
Vue.component('snRadioGroup', snRadioGroup)

Vue.use(snackbard)
Vue.use(Router)

if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
  Vue.config.devtools = true
}

const { VueApollo, provider } = apolloClient()
Vue.use(VueApollo)

Vue.prototype.$sentry = Sentry([{ key: 'pack', value: 'Login' }])
Vue.prototype.$axios = axios

Vue.directive('mask', VueMaskDirective)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/password_reset/:authentication_token',
      component: PasswordReset
    }
  ]
})

const app = new Vue({
  el: '#app-container',
  vuetify,
  apolloProvider: provider,
  router,
  render: createEle => createEle(LoginApp)
})
