<template>
  <div>
    <sn-gradient-header
      :overlay-radius="24"
      :hide-back-btn="true"
      :class="['gradient-header', {'sm': isMobile}]"
    >
      <div style="height: 84px" />
    </sn-gradient-header>
    <div
      id="password-reset"
      class="password-reset-container"
      :style="cssVars"
    >
      <v-card
        :class="['password-reset-card mt-10', {'sm': isMobile}]"
        :flat="isMobile"
      >
        <div class="sn-h2 mb-6">
          Update your password
        </div>
        <v-form
          ref="form"
          @submit.prevent
        >
          <sn-label
            title="New password"
            data-cy="password-field-label"
            class="pb-2"
          />
          <sn-password
            v-model="newPassword"
            class="mb-4"
            tabindex="1"
            :sn-input-props_hint="newPasswordHint"
            :sn-input-props_error-messages="newPasswordError"
            @blur="enableNewPassValidation"
            @keyup="validateNewPassword"
          />
          <sn-label
            title="Confirm new password"
            data-cy="confirm-password-field-label"
            class="pb-2"
          />
          <sn-password
            v-model="confirmPassword"
            class="mb-4"
            tabindex="2"
            :sn-input-props_error-messages="confirmPasswordError"
            @blur="enableConfirmPassValidation"
            @keyup="validateConfirmPassword"
          />
        </v-form>
        <v-row no-gutters>
          <sn-btn
            size="full-width"
            data-cy="update-password-btn"
            tabindex="3"
            text="Update password"
            :loading="btnLoading"
            @click="updatePassword"
          />
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import snValidationsService from '~/services/snValidationsService.js'
import brandThemeMixin from '~/components/common/brandThemeMixin'
import logo from '~/assets/images/logos/ncino_mortgage_logo_dark.svg'
import logoNoText from '~/assets/images/logos/ncino_logo_no_text_color.png'
import {
  getUserByAuthToken
} from './passwordResetQueries.gql'

export default {
  name: 'PasswordReset',
  mixins: [brandThemeMixin],
  data () {
    return {
      newPassword: '',
      confirmPassword: '',
      newPassValidationEnabled: false,
      confirmPassValidationEnabled: false,
      newPassIsValid: false,
      confirmPassIsValid: false,
      btnLoading: false,
      newPasswordHint: 'Password must have at least 9 characters, one uppercase letter, one lowercase letter, and one number',
      newPasswordError: '',
      confirmPasswordError: '',
      logo,
      logoNoText,
      effectiveLogoUrl: '',
      effectiveAppIconUrl: ''
    }
  },
  computed: {
    effectiveLogo () {
      return this.effectiveLogoUrl || this.logo
    },
    isMobile () {
      return this.$vuetify?.breakpoint?.mdAndDown
    },
    contentMaxWidth () {
      if (this.isMobile) {
        return '100%'
      } else {
        return '560px'
      }
    },
    cssVars () {
      return {
        '--content-max-width': this.contentMaxWidth
      }
    },
    loading () {
      return this.$apollo?.loading
    },
    smallLogo () {
      return this.effectiveAppIconUrl || this.logoNoText
    }
  },
  async mounted () {
    if (this.$route.params.authentication_token) await this.$apollo.queries.user.refetch()
  },
  methods: {
    validateNewPassword () {
      if (this.newPassword) {
        this.$emit('change', true)
      } else {
        this.$emit('change', false)
      }
      if (this.newPassValidationEnabled) {
        this.newPassIsValid = snValidationsService.password(this.newPassword)
        const newPassMessage = 'Password must have at least 9 characters, one uppercase letter, one lowercase letter, and one number'
        this.newPasswordError = this.newPassIsValid ? '' : newPassMessage
        this.newPasswordHint = this.newPassIsValid ? newPassMessage : ''
      }
      if (this.confirmPassValidationEnabled) {
        this.validateConfirmPassword()
      }
    },
    validateConfirmPassword () {
      if (this.confirmPassword) {
        this.$emit('change', true)
      } else {
        this.$emit('change', false)
      }
      if (!this.confirmPassValidationEnabled && this.confirmPassword.length > 8) {
        this.enableConfirmPassValidation()
      } else if (this.confirmPassValidationEnabled) {
        this.confirmPassIsValid = this.newPassword === this.confirmPassword
        this.confirmPasswordError = this.confirmPassIsValid ? '' : 'Passwords do not match'
      }
    },
    enableNewPassValidation () {
      this.newPassValidationEnabled = true
      this.validateNewPassword()
    },
    enableConfirmPassValidation () {
      this.confirmPassValidationEnabled = true
      this.validateConfirmPassword()
    },
    async updatePassword () {
      this.btnLoading = true

      this.user.password = this.newPassword
      this.user.password_confirmation = this.confirmPassword
      await this.$axios.post(`/password_reset/${this.$route.params.authentication_token}`, {
        user: this.user
      })
      this.btnLoading = false
      this.$router.push('/login')
    }
  },
  apollo: {
    user: {
      query: getUserByAuthToken,
      skip () {
        return !this.$route.params.authentication_token
      },
      variables () {
        return {
          authentication_token: this.$route.params.authentication_token
        }
      },
      update (data) {
        return data.user
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/snui';

.password-reset-container {
  margin: 0 auto;
  padding: 0 16px;
  max-width: var(--content-max-width);
}

.password-reset-card {
  position: relative;
  width: 100vw;
}

.password-reset-card.sm {
  top: 10px;
  border: none;
  box-shadow: none;
  padding: 0;
}

.password-reset-card:not(.sm) {
  top: -155px;
  padding: 56px;
  border-radius: 16px;
  box-shadow: $sn-shadow-depth-4
}

::v-deep .v-avatar .v-image {
  border-radius: 0px !important;
}

.gradient-header.sm {
  height: 80px !important;
}

.gradient-header:not(.sm) {
  height: 230px !important;
}
</style>
